import { useQuery, useQueryClient } from '@tanstack/react-query'
import { aOrganization, useAtomValue } from '~/atoms'
import type { ModelFind, Report } from '~/models'
import type { ApiResponse } from '../utils'

type FetchReportsResponse = ApiResponse & {
  reports: Report[]
  count: number
  nextOffset: number
}

export async function fetchReports({
  find,
  project,
  limit,
  offset,
  signal,
}: {
  find: ModelFind
  project?: {}
  limit?: number
  offset?: number
  signal?: AbortSignal
}): Promise<FetchReportsResponse> {
  const response = await fetch('/api/reports', {
    body: JSON.stringify({
      find,
      project,
      limit,
      offset,
    }),
    method: 'POST',
    signal,
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error loading reports', result.error)
    throw new Error(`There was a problem fetching results: ${result.error}`)
  }
  return result
}

export function useAdminReportsQuery({
  key,
  find,
  enabled = true,
  keepPreviousData = true,
  refetchOnWindowFocus = false,
  refetchOnMount = true,
  refetchInterval,
}: {
  key: string[]
  find?: { [key: string]: any }
  enabled?: boolean
  keepPreviousData?: boolean
  refetchOnWindowFocus?: boolean
  refetchOnMount?: boolean
  refetchInterval?: number
}) {
  const organization = useAtomValue(aOrganization)
  const queryClient = useQueryClient()
  const reportQuery = useQuery({
    queryKey: key,
    queryFn: () =>
      fetchReports({
        find: { ...find, 'organization._id': organization!._id },
      }),
    enabled,
    // keepPreviousData,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchInterval,
  })
  return {
    isLoading:
      reportQuery.isLoading ||
      reportQuery.isFetching ||
      reportQuery.isRefetching,
    isError: reportQuery.isError,
    reports: reportQuery.data?.reports || [],
    refetch: reportQuery.refetch,
    updateReportsData: (reports: Report[]) => {
      queryClient.setQueryData(
        key,
        (previousData?: { success: boolean; reports: Report[] }) => {
          if (!previousData) return undefined
          return {
            success: true,
            reports: [...reports],
          }
        }
      )
    },
  }
}
